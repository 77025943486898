<template>
  <router-view />
</template>
<script>
export default {
  created() {
    const langOption = this.$route.query.lang;
    if (langOption != undefined) {
      this.updateLanguageIfNeeded(langOption);
    }
  },
  methods: {
    updateLanguageIfNeeded(language) {
      this.$cookies.set("SelectedLang", language.toUpperCase());
      this.$i18n.locale = language.toUpperCase();
    },
  },
};
</script>